// export const base_url = `https://forexbotapi.metagainfx.com/`

// export const base_url = `http://192.168.1.12:8100/`
// http://192.168.1.28:8100/api-docs/
// export const base_url = `http://192.168.1.20:8100/`

// export const base_url = `https://crypto-trading.jstechservices.us/`

// export const base_url = `http://209.97.173.161:8100/` 

// new base url
export const base_url = `https://crypto-trading.jstechservices.us/`
// export const base_url = `http://192.168.1.72:8100/`

// export const base_url = `http://localhost:8100/` 
