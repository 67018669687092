import React from 'react'
import { NavLink, useNavigate, useLocation } from 'react-router-dom'
import toast from 'react-hot-toast'
import _fetch from '../Config/api'
import { base_url } from '../Config/config'
import { useState, useEffect } from 'react'
import { ColorRing } from 'react-loader-spinner';
import businessman from '../assets/img/businessman.jpg'
import logo from '../assets/img/logo.png'
function Verfications() {
    const { state } = useLocation();
    console.log('state', state);

    const [email, setEmail] = useState(state?.email || "");
    const [Otp, setOtp] = useState();
    const [loading, setLoading] = useState(false);
    const [loadings, setLoadings] = useState(false);
    const navigate = useNavigate();
    const handleSignUp = async (e) => {
        e.preventDefault();
        if (!email || !Otp) {
            toast.dismiss();
            toast.error("Please enter the details");
            return;
        }
        setLoadings(true)
        try {
            const signupData = {
                email: email,
                otp: Otp
            };
            let response = await _fetch(`${base_url}api/user/verifySignIn`, 'POST', signupData, {});

            if (response.success === true) {
                localStorage.setItem("token", response.token)
                toast.success(response.message);
                navigate('/dashboard');
            } else {
                toast.error(response.message);
            }
        }
        catch (error) {
            console.log("Error details", error);
        } finally {
            setLoadings(false);
        }
    }
    const handleresend = async (e) => {
        e.preventDefault();
        if (!email) {
            toast.dismiss();
            toast.error("Please enter the details");
            return;
        }
        setLoading(true);
        try {
            const signupData = {
                email: email,
                type: "resendOtp"

            };
            let response = await _fetch(`${base_url}api/user/resendOtp`, 'POST', signupData, {});

            if (response.success === true) {
                toast.success(response.message);

            } else {
                toast.error(response.message);
            }
        }
        catch (error) {
            console.log("Error details", error);
        } finally {
            setLoading(false);
        }
    }
    return (
        <>
            <div className="conatiner-fluid">
                <div className="d-flex justify-content-center main-box-sing ">
                    <div className="sign-page ls">
                        <div className="heading text-center  ">
                            <NavLink to='/'>   <i class="fa-solid fa-arrow-left dashboard-3 " style={{ alignItems: "center" }}></i> </NavLink>
                            <div className='d-flex'>

                                <NavLink to='/'><img className="welcome" src={logo} alt="" />

                                </NavLink>
                            </div>
                            <img className="image_border mt-3" src={businessman} alt="..." />
                            <h3 className='H_3 email'>Account Verification</h3>
                        </div>
                        <form >
                            <div className="mb-3">
                                <label htmlFor="exampleInputEmail1" className="form-label">Email </label>
                                <input type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} autoComplete='off' className="form-control" aria-describedby="emailHelp" readOnly />
                            </div>

                            <div className="mb-3">
                                <label htmlFor="exampleInputPassword1" className="form-label">Otp</label>
                                <input type="text" id="sponser" onChange={(e) => setOtp(e.target.value)} autoComplete='off' className="form-control" />
                            </div>
                            <div className='butt'>

                                <button type="submit" className="btn iop btn-primary" onClick={handleSignUp}>
                                    {loadings ? (
                                        <ColorRing
                                            height={20}
                                            width={20}
                                            color="#fff"
                                            ariaLabel="loading"
                                        />
                                    ) : (
                                        'Submit'
                                    )}
                                </button>
                                <button type="submit" className="btn  iop btn-primary m" onClick={handleresend}>

                                    {loading ? (
                                        <ColorRing
                                            height={20}
                                            width={20}
                                            color="#fff"
                                            ariaLabel="loading"
                                        />
                                    ) : (
                                        'Resend OTP'
                                    )}
                                </button>
                            </div>
                            <div>

                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Verfications
